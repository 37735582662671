// Deprecated
import React, { PureComponent } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import debounce from "debounce-promise"
import striptags from "striptags"
import ReactSelect from "react-select"
import { AsyncPaginate } from "react-select-async-paginate"

// helper
import { valueConverter } from "app/static/frontend/forms/WrappedFormFields/helperFunctions"

// constants
import { SEARCHIFY_PROMPT_TEXT } from "shared/imports/sharedConstants"
import { QuorumBlue } from "app/static/frontend/design-constants"

// css
import "app/static/frontend/selects/components/stylus/quorum-react-select.styl"

export default class Select extends PureComponent {
    constructor(props) {
        super(props)

        if (props.requiresBaseComponent) {
            this.state = {
                inputValue: "",
                isTowardsBottom: false,
            }
        } else {
            this.state = {
                isTowardsBottom: false,
            }
        }
    }

    onInputChange = (inputValue) => {
        return this.setState({ inputValue })
    }

    // render differently if it is a header8
    renderOption(option) {
        // if the parent wants to override this method
        if (this.props.renderOption) {
            // let them
            return this.props.renderOption(option)
        }
        // default implementation
        if (option.disabled) {
            return <strong style={{ color: "black" }}>{striptags(option[this.props.label])}</strong>
        }

        return option.isCurrentUser ? (
            <strong style={{ color: "black" }}>{`${striptags(option[this.props.label])} (You)`}</strong>
        ) : (
            <span>{striptags(option[this.props.label])}</span>
        )
    }

    /**
     * Takes in a Ref object and sets the 'isTowardsBottom' state to true if
     * the position is towards the lower third of the screen and false if not
     * @name getIsRefTowardsBottom
     * @function
     * @param {Ref} ref
     * @returns {void}
     */
    getIsRefTowardsBottom = (ref) => {
        if (ref) {
            const dropUpDisplayArea = window.innerHeight / 3
            const distToBottom = window.innerHeight - ref.getBoundingClientRect().top
            const isTowardsBottom = distToBottom < dropUpDisplayArea
            this.setState({ isTowardsBottom })
        }
    }

    render() {
        // For certain entry points in our application (like the Unsubscribe
        // Page and other public facing bundles), we need to use our Selects,
        // but must explicitly exclude Search and Searchify from the application's
        // dependency graph. Search includes in its dependency graph whole sets of
        // functionality that will not work without globally scoped variables and
        // resources like Userdata or D3.
        const SearchifyWrapper = window.SearchifyWrapper
        const value =
            // TODO: this is an antipattern;
            // any immutable data should be converted in the container with
            // with-immutable-props-to-js withImmutablePropsToJS()
            this.props.value && this.props.value.toJS ? this.props.value.toJS() : this.props.value
        const classNameArray = []

        const { defaultClassName } = this.props
        if (defaultClassName) {
            classNameArray.push(defaultClassName)
        }

        const { className } = this.props
        if (className) {
            classNameArray.push(className)
        }

        const classNamesJoined = classNameArray.length ? classNameArray.join(" ") : undefined
        const menuExpandsDown = (state) =>
            (!this.state.isTowardsBottom && state.menuIsOpen) ||
            (this.state.isTowardsBottom && !this.props.allowExpandUp && state.menuIsOpen)
        const menuExpandsUp = (state) => this.state.isTowardsBottom && this.props.allowExpandUp && state.menuIsOpen

        const sharedProps = {
            className: classNamesJoined,
            classNamePrefix: this.props.defaultClassName,
            defaultOptions: true,
            escapeClearsValue: true,
            filterOption: this.props.filterOption || this.filterOption,
            formatOptionLabel: (option) => this.renderOption(option),
            isClearable: this.props.clearable,
            isMulti: this.props.multi,
            onChange: (changedValue, { action }) => {
                // https://react-select.com/advanced#action-meta
                // https://github.com/JedWatson/react-select/blob/master/docs/pages/props/index.tsx#L72
                if (action === "clear" && this.props.onClear) {
                    this.props.onClear()
                }

                // Determine if this.props.value is valid based on multi-select or single-select logic
                const hasValidValue = this.props.multi
                    ? // For multi-select:
                      // Check if this.props.value is truthy, then check length (for arrays) or size (for immutable.js array)
                      this.props.value && (this.props.value.length || this.props.value.size)
                    : // For single-select:
                      // Check if this.props.value is neither undefined nor null
                      this.props.value !== undefined && this.props.value !== null

                // Check if either hasValidValue or changedValue is truthy
                if (hasValidValue || changedValue) {
                    // this is necessary due to a breaking change between react-select 2 and 3
                    // "We rectify this in 3.0.0, on removal of all selected values in an isMulti Select,
                    // the value passed to onChange is null and not []."
                    const valueToPass = changedValue || (this.props.multi ? [] : {})

                    // Call onChange with the determined value
                    this.props.onChange(valueToPass)
                }
            },
            menuPlacement: this.props.allowExpandUp && this.state.isTowardsBottom ? "top" : "bottom",
            onMenuOpen: this.props.onMenuOpen,
            openMenuOnClick: true,
            placeholder: this.props.placeholder,
            styles: {
                menu: (base) =>
                    this.props.allowExpandUp && this.state.isTowardsBottom
                        ? {
                              ...base,
                              border: `thin solid ${QuorumBlue}`,
                              borderBottom: "none",
                              marginBottom: 0,
                          }
                        : {
                              ...base,
                              border: `thin solid ${QuorumBlue}`,
                              borderTop: "none",
                          },
                control: (base, state) => {
                    if (menuExpandsUp(state)) {
                        return {
                            ...base,
                            borderTopRightRadius: 0,
                            borderTopLeftRadius: 0,
                            borderBottomRightRadius: "4px",
                            borderBottomLeftRadius: "4px",
                        }
                    } else if (menuExpandsDown(state)) {
                        return {
                            ...base,
                            borderBottomRightRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderTopRightRadius: "4px",
                            borderTopLeftRadius: "4px",
                        }
                    } else {
                        return { ...base }
                    }
                },
                // if we pass isHidden to an item in the select options array,
                // do not show it
                multiValue: (base, state) => {
                    if (state.data.isHidden) {
                        return {
                            ...base,
                            display: "none",
                        }
                    }

                    return base
                },
                // once we hide the remove button we need to update the
                // right padding of the multi select
                multiValueLabel: (base, state) => {
                    if (state.data.isFixed) {
                        return {
                            ...base,
                            paddingRight: "6px",
                        }
                    }

                    return base
                },
                // https://react-select.com/home#fixed-options
                // https://github.com/JedWatson/react-select/blob/master/docs/examples/FixedOptions.js
                multiValueRemove: (base, state) => {
                    if (state.data.isFixed) {
                        return {
                            ...base,
                            display: "none",
                        }
                    }
                    return base
                },
                option: (base, state) => {
                    if (state.data.isDisabled) {
                        return {
                            ...base,
                            cursor: "not-allowed",
                        }
                    }
                    return base
                },
            },
        }

        // most of our component are async
        if (this.props.async) {
            return (
                <div
                    className={classNames({
                        "quorum-reactselect-wrapper": true,
                        "is-searchify-select": !!this.props.advancedSearchifyConfig,
                        "is-design-system": this.props.isDesignSystem,
                    })}
                    data-cy={this.props.dataCy}
                    ref={this.getIsRefTowardsBottom}
                >
                    {this.props.advancedSearchifyConfig && !this.props.isDisabled && (
                        <SearchifyWrapper
                            tooltipLabel={SEARCHIFY_PROMPT_TEXT}
                            searchifyConfigs={this.props.advancedSearchifyConfig}
                        >
                            <i className="fa fa-search-plus launch-select-searchify" />
                        </SearchifyWrapper>
                    )}
                    <AsyncPaginate
                        {...this.props}
                        {...sharedProps}
                        // to force rerender if props.options changes
                        key={
                            this.props.shouldReloadOnFilterChange
                                ? `${(this.props.options[0] && this.props.options[0].value) || "0"}-${
                                      this.props.options.length
                                  }`
                                : null
                        }
                        value={
                            value !== undefined &&
                            value !== null &&
                            this.props.options.length &&
                            valueConverter(
                                this.props.options,
                                this.props.multi,
                                this.props.shouldHideExtraValues,
                                value,
                            )
                        }
                        // Async
                        cacheOptions
                        inputValue={
                            // manually using the AsyncPaginateBase requires maintaining the state of the input;
                            // this allows us to open the dropdown menu by default with defaultMenuIsOpen and requiresBaseComponent
                            // https://github.com/vtaits/react-select-async-paginate/blob/master/packages/react-select-async-paginate/src/async-paginate-base.jsx
                            // https://github.com/vtaits/react-select-async-paginate/blob/master/packages/react-select-async-paginate/src/__stories__/Manual.jsx
                            this.props.requiresBaseComponent ? this.state.inputValue : undefined
                        }
                        // https://github.com/JedWatson/react-select/issues/3075#issuecomment-450194917
                        loadOptions={debounce(this.props.loadOptions, 500)}
                        onInputChange={this.props.requiresBaseComponent && this.onInputChange}
                        ref={this.props.parentRef}
                    />
                </div>
            )
        } else {
            const loadOptions = this.props.loadOptions()
            return (
                <div data-cy={this.props.dataCy} ref={this.getIsRefTowardsBottom}>
                    <ReactSelect
                        {...this.props}
                        {...sharedProps}
                        value={
                            value !== undefined &&
                            value !== null &&
                            loadOptions.length &&
                            valueConverter(loadOptions, this.props.multi, this.props.shouldHideExtraValues, value)
                        }
                        // Sync
                        options={loadOptions}
                        ref={this.props.parentRef}
                        menuShouldScrollIntoView={this.props.menuShouldScrollIntoView}
                    />
                </div>
            )
        }
    }
}
Select.propTypes = {
    advancedSearchifyConfig: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    allowExpandUp: PropTypes.bool,
    async: PropTypes.bool,
    className: PropTypes.string,
    clearable: PropTypes.bool,
    dataCy: PropTypes.string,
    defaultClassName: PropTypes.string,
    filterOption: PropTypes.func,
    label: PropTypes.string,
    loadOptions: PropTypes.func.isRequired,
    menuShouldScrollIntoView: PropTypes.bool,
    multi: PropTypes.bool,
    onChange: PropTypes.func,
    onMenuOpen: PropTypes.func,
    options: PropTypes.array,
    pagination: PropTypes.bool,
    parentRef: PropTypes.object,
    placeholder: PropTypes.string,
    renderOption: PropTypes.func,
    requiresBaseComponent: PropTypes.bool,
    shouldHideExtraValues: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
}
Select.defaultProps = {
    allowExpandUp: false,
    async: true,
    clearable: true,
    defaultClassName: "Select",
    label: "label",
    menuShouldScrollIntoView: true,
    multi: false,
    pagination: true,
    placeholder: "Select...",
    // necessary if you need to set defaults for defaultMenuIsOpen, menuIsOpen, or inputValue props
    requiresBaseComponent: false,
    shouldHideExtraValues: false,
    // "undefined" instead of null can cause react-select to have some controlled
    //  vs. uncontrolled issues.
    // https://github.com/JedWatson/react-select/issues/3066#issuecomment-643000641
    value: null,
}
