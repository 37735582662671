import { useMutation } from "@tanstack/react-query"

import { api } from "@/api"

export interface FetchPlaceholdersFromMessageParams {
    content: string
    subject: string
    fixed_pre_content: string
    fixed_post_content: string
    person_id: string
}

export const fetchPlaceholdersFromMessage = async (params: FetchPlaceholdersFromMessageParams) => {
    const response = await api.get({
        model: DjangIO.app.grassroots.campaign.models.GrassrootsCampaign,
        action: "replace_message_placeholder_with_legislator",
        params: params as unknown as Record<string, string>,
    })
    return response?.json?.()
}

export const useFetchPlaceholdersFromMessage = (officialId?: string) => {
    const mutation = useMutation({
        mutationKey: ["fetchPlaceholdersFromMessage", officialId],
        mutationFn: (params: FetchPlaceholdersFromMessageParams) => fetchPlaceholdersFromMessage(params),
    })

    return mutation
}
