export const constants = {
    colMdWidth: 992,
    campaignMessagePreviewReduxFormKey: "campaignMessagePreviewForm",
    contactUsFormReduxFormKey: "contactUsForm",
    defaultBackgroundGrey: "#D5DCEF",
    defaultBorderColor: "#000000",
    defaultPrimaryColor: "#000000",
    defaultLightGrey: "#CCCCCC",
    donationFormReduxFormKey: "donationForm",
    eventReduxFormKey: "eventForm",
    inputFieldBorderColor: "#ced5e7",
    freshLightGrey: "#F1F3F5",
    freshMediumGrey: "#495057",
    freshDarkGrey: "#212529",
    mobileWidth: 768,
    whiteColor: "#FFFFFF",
    quorumBlue: "#503ABD",

    colors: {
        green: [
            "#F0FDF2",
            "#DDFBE1",
            "#BDF5C6",
            "#BDF5C6",
            "#4FD967",
            "#2ACB47",
            "#1B9E32",
            "#197C2B",
            "#196227",
            "#062D0F",
        ],
        gray: [
            "#FAFBFC",
            "#F1F3F5",
            "#E9ECEF",
            "#DEE2E6",
            "#CED4DA",
            "#ADB5BD",
            "#868E96",
            "#495057",
            "#343A40",
            "#212529",
        ],
    },
}

// style constants separated by theme
export const themeStyleConstants = {
    [DjangIO.app.grassroots.types.ActionCenterTheme.impact.value as number]: {
        headerHeight: 85,
        pageDesktopStyle: `
            margin-left: 80px;
            margin-right: 80px;
            margin-top: 30px;
            padding-top: 30px;
            padding-right: 0px;
            padding-left: 0px;
        `,
    },
}

export const placeholders = {
    CONTACT_FIRST_NAME: "{{contact_first_name}}",
    CONTACT_LAST_NAME: "{{contact_last_name}}",
    CONTACT_FULL_NAME: "{{contact_full_name}}",
    CONTACT_ADDRESS: "{{contact_address}}",
    CONTACT_EMAIL: "{{contact_email}}",
    CONTACT_STATE_NAME: "{{contact_state_name}}",
    CONTACT_COUNTRY_NAME: "{{contact_country_name}}",
    CONTACT_STATE_CAPITAL: "{{contact_state_capital}}",
    CONTACT_APPROPRIATE_GREETING: "{{contact_appropriate_greeting}}",
    CONTACT_ORGANIZATION_NAME: "{{contact_organization_name}}",
    CONTACT_STATE_UPPER_REP: "{{contact_state_upper_rep}}",
    CONTACT_STATE_LOWER_REP: "{{contact_state_lower_rep}}",
    CONTACT_REPRESENTATIVE: "{{contact_representative}}",
    CONTACT_SENATORS: "{{contact_senators}}",
    CONTACT_STATE_UPPER_REP_PHONE_NUMBER: "{{contact_state_upper_rep_phone_number}}",
    CONTACT_STATE_LOWER_REP_PHONE_NUMBER: "{{contact_state_lower_rep_phone_number}}",
    CONTACT_REPRESENTATIVE_PHONE_NUMBER: "{{contact_representative_phone_number}}",
    CONTACT_SENATORS_PHONE_NUMBER: "{{contact_senators_phone_number}}",
    CONTACT_CITY_AND_STATE: "{{contact_city_and_state}}",
    CONTACT_PHONE_NUMBER: "{{contact_phone_number}}",
    CONTACT_QUORUM_ID: "{{contact_quorum_id}}",
    TARGET_FIRST_NAME: "{{target_first_name}}",
    TARGET_LAST_NAME: "{{target_last_name}}",
    TARGET_APPROPRIATE_GREETING: "{{target_appropriate_greeting}}",
    TARGET_PRONOUN: "{{target_pronoun}}",
    TARGET_PRONOUN_OBJECT: "{{target_pronoun_object}}",
    TARGET_FULL_NAME: "{{target_full_name}}",
    TARGET_TITLE: "{{target_title}}",
    TARGET_TWITTER_HANDLE: "{{target_twitter_handle}}",
    TARGET_STATE: "{{target_state}}",
    TARGET_ORGANIZATION_NAME: "{{target_organization_name}}",
    TARGET_TITLE_LAST_NAME: "{{target_title_last_name}}",
    TARGET_OFFICE_OR_COMMITTEE: "{{target_office_or_committee}}",
    TARGET_CHAMBER: "{{target_chamber}}",
    TARGET_DISTRICT_TAG: "{{target_district_tag}}",
}
