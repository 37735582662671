export const loadCameraTagAssets = () => {
    const link = document.createElement("link")
    link.id = "camera-tag-css"
    link.rel = "stylesheet"
    link.href = "https://www.cameratag.com/v15/css/cameratag.css"

    const script = document.createElement("script")
    script.type = "text/javascript"
    script.src = "https://www.cameratag.com/api/v15/js/cameratag.min.js"
    script.async = true
    script.id = "camera-tag-js"

    return [link, script]
}
