import { createSelector } from "reselect"
import { fromJS } from "immutable"

// selectors
import {
    selectCircleColor,
    selectFa4Icon,
    selectFa5IconFamily,
    selectHref,
    selectIcon,
    selectIconColor,
    selectImageUrl,
    selectSegue,
    selectSegueArgs,
    selectSubtext,
    selectText,
} from "app/static/frontend/projects-new/list/selectors/list-item-selectors"

// helpers
import { stripHtml } from "quorum/static/frontend/marketing-website/constants/functions"
import { parseHtmlToReact } from "app/static/frontend/imports/desktopHelperFunctions"
/**
 * Maps data from an evaluated widget schema to a corresponding qdt
 *
 * @param {Array[Object]} data - the data from the evaluated widget
 * @param {Array[Object]} filters - the SafedSearch filters from the evaluated widget
 * @param {int} qdt - the QuorumDataType of the evaluated widget
 * @param {Function} segueToSearch - segues to a specific search dataset
 * @param {Function} segueToProfile - segues to a specific profile
 *
 * @return {Array[Object]} returns the data normalized to conform to the generic ListWidget
 */
export const getListWidgetData = ({
    calendarEvent,
    datum,
    filters,
    isCalendarEvent,
    isExternal,
    qdt,
    segueToProfile,
    segueToSearch,
}) => {
    if (isCalendarEvent) {
        let firstLine = []
        let firstLineRaw = []
        for (const note of calendarEvent) {
            let text = stripHtml(
                note.cache_json.interaction_type || note.custom_interaction_type
                    ? note.humanized_text || note.text
                    : note.text || note.humanized_text,
            )
            const startTime = moment(note.date).format("hh:mm A")
            const endTime = moment(note.date).add(note.duration, "minutes").format("hh:mm A")
            const timezone = note.searchable_date.split(" ").slice(-1)[0]
            firstLineRaw.push(`${startTime} - ${endTime} ${timezone} ${text}`)
            text = `<p><b>${startTime} - ${endTime} ${timezone}</b> ${text}</p>`
            firstLine.push(parseHtmlToReact(text, note.id, isExternal))
        }
        return {
            firstLine: firstLine,
            firstLineRaw: firstLineRaw,
            iconText: datum.dayKey,
        }
    }

    const dataType = qdt.value
    // fromJS is an expensive operation, so we should only compute it once
    const item = fromJS(datum)
    const isCampaignFinance = [
        DjangIO.app.models.QuorumDataType.receipt.value,
        DjangIO.app.models.QuorumDataType.disbursement.value,
    ].includes(dataType)

    const fa4Icon = selectFa4Icon(undefined, {
        dataType,
        item,
    })
    const segue = selectSegue(undefined, {
        dataType,
        isExternal,
        item,
        segueToProfile,
        segueToSearch,
    })

    return {
        circleColor: selectCircleColor(undefined, {
            dataType,
            item,
        }),
        fa4Icon,
        firstLine: selectText(undefined, {
            dataType,
            isExternal,
            isWidget: true,
            item,
        }),
        // returns the raw text string when isWidget is falsy
        firstLineRaw: selectText(undefined, {
            dataType,
            item,
        }),
        href:
            !isCampaignFinance &&
            selectHref(undefined, {
                dataType,
                isExternal,
                item,
            }),
        // ignored in QuorumDesign/src/Components/Compounds/ListWidget/index if fa4Icon exists
        icon: selectIcon(undefined, {
            dataType,
            isWidget: true,
            item,
        }),
        // Vote icons have colors
        iconColor: selectIconColor(undefined, {
            dataType,
            item,
        }),
        // if we have not selected an fa4Icon,
        // we want to use the QuorumDataType fa5_icon and fa5_icon_family
        iconFamily: !fa4Icon && selectFa5IconFamily(undefined, { dataType, item }),
        id: datum.id,
        imageUrl: selectImageUrl(undefined, {
            dataType,
            item,
        }),
        secondLineItems: selectSubtext(undefined, {
            dataType,
            item,
        }),
        ...(segue && {
            segue: () =>
                segue(
                    ...selectSegueArgs(undefined, {
                        dataType,
                        ...(filters.length && {
                            filtersForSearch: {
                                filters: {
                                    advanced_search: filters[0].advanced_search,
                                },
                            },
                        }),
                        item,
                    }),
                ),
        }),
    }
}

/**
 * Grabs the icon and iconFamily for an empty ListItem
 *
 * @param {int} qdt - the QuorumDataType of the evaluated widget
 *
 * @return {Array[Object]} returns the data normalized to conform to the generic ListWidget
 */
export const getListWidgetDataEmpty = ({ qdt }) => {
    const dataType = qdt.value

    return {
        // ignored in QuorumDesign/src/Components/Compounds/ListWidget/index if fa4Icon exists
        icon: selectIcon(undefined, {
            dataType,
            isWidget: true,
        }),
        // if we have not selected an fa4Icon,
        // we want to use the QuorumDataType fa5_icon and fa5_icon_family
        iconFamily: selectFa5IconFamily(undefined, { dataType }),
    }
}

/**
 * Maps Resource dehydrate_extra calls per data_type
 *
 * @param {int} qdt - the data_type of the SafedSearch schema
 *
 * @return {array[string]} returns an array of dehydrate_extra method strings
 */
export const getListWidgetDehydrateExtra = ({ qdt }) => {
    switch (qdt.value) {
        case DjangIO.app.models.QuorumDataType.person.value:
        case DjangIO.app.models.QuorumDataType.staffer.value:
        case DjangIO.app.models.QuorumDataType.press_contact.value:
            return [["most_recent_role"]]
        case DjangIO.app.models.QuorumDataType.bill.value:
            return [["sponsors"]]
        case DjangIO.app.models.QuorumDataType.document.value:
            return [["official_image"]]
        case DjangIO.app.models.QuorumDataType.send_email.value:
            return [["bulk_email"]]
        default:
            return undefined
    }
}

/**
 * Maps data from an evaluated widget schema to a corresponding filter object
 * for use with the showInSearch list action-creator
 * Takes into account some list-specific edge cases (only added_ids, flattening filters, etc)
 *
 * @param {Object} widget - the widget schema
 *
 * @return {Object} returns the data in a format that showInSearch can comprehend
 */
export const selectShowInSearchPermanentFilters = ({ filters, safedSearch }) => ({
    dataType: safedSearch.data_type,
    filters: {
        // if we don't have any filters, we want to override
        // the default filters in search
        // (if we don't, app/querymethods added_ids() will union rather than intersect)
        ...(safedSearch.added_ids &&
        safedSearch.added_ids.length &&
        (!safedSearch.filters || !safedSearch.filters.length)
            ? { id__in: safedSearch.added_ids }
            : {}),
        // if we have added_ids with filters or deleted ids,
        // pass the added_ids
        ...(safedSearch.added_ids &&
        safedSearch.added_ids.length &&
        ((safedSearch.filters && safedSearch.filters.length) ||
            (safedSearch.deleted_ids && safedSearch.deleted_ids.length))
            ? { added_ids: safedSearch.added_ids }
            : {}),
        // if we have any deleted_ids, just pass them
        ...(safedSearch.deleted_ids && safedSearch.deleted_ids.length ? { deleted_ids: safedSearch.deleted_ids } : {}),
        // if we have any filters, flatten them down and apply them in search
        ...((safedSearch.filters && safedSearch.filters.length) || (filters && filters.length)
            ? // flatten these down so we can apply all filters to the search QuerySet
              {
                  filters: Object.assign(
                      ...[
                          // base SafedSearch filters
                          ...safedSearch.filters,
                          // arbitrary filters
                          ...(filters || []),
                      ].map((item) => item),
                  ),
              }
            : {}),
    },
})

/**
 * Selects the countLabel for the QuorumDesign/src/Components/Compounds/Widget
 *
 * @param {Number} count - the evaluated count of the widget schema
 * @param {Enum<QuorumDataType>} qdt - the QuorumDataType enum
 *
 * @return {String} returns a singular or plural qdt label
 */
export const selectListWidgetCountLabel = ({ count, qdt, isCalendarEvent = false }) => {
    if (isCalendarEvent) {
        return count === 1 ? "Calendar Event" : "Calendar Events"
    }
    return qdt && (count === 1 ? qdt.singular : qdt.plural)
}

/**
 * Selects the <Widget> widgetFallback for the app/static/frontend/widgets/components/ListWidget
 *
 * @param {Bool} canLoad - whether or not we actually have any data to load from a valid widget schema shape
 * @param {Arrray} data - the Array of potentially evaluated data from the widget_engine
 * @param {Bool} isLoading - ListWidget.jsx isLoading state
 *
 * @return {Array<Object>} returns a fallback string
 */
export const getListWidgetFallback = ({ canLoad, data, isLoading }) => {
    if (canLoad && !data.length && isLoading) {
        return "Loading..."
    } else if (!canLoad && !data.length && !isLoading) {
        return "Your Selection of Filter(s) Returned No Data"
    }
}

/**
 * Selects the headerIcons for the QuorumDesign/src/Components/Compounds/Widget
 *
 * @param {Bool} canLoad - whether or not we actually have data to load
 * @param {Bool} dataSources - one of the safed_search entries from widget.data_sources
 * @param {Bool} editing - whether the Widget is being edited
 * @param {Array<Object>} headerIcons - the default headerIcons that we are overriding
 * @param {Function} showInSearch - segue that will clean up the filters and segue to the corresponing search FLV
 *
 * @return {Array<Object>} returns an array of icons
 */
export const getListWidgetHeaderIcons = ({
    canLoad,
    dashboardId,
    dataSources,
    editing,
    externalDownloadsDisabled,
    headerIcons,
    isExternal,
    showInSearch,
    widgetKey,
    // csv download
    additionalFilters,
    dehydrateExtras,
    evaluateWidget,
    isDownloading,
    setIsDownloading,
    widget,
}) =>
    // if we are not editing and we have a valid widget schema,
    // then we want to override the generic headerIcons passed from Widget.jsx
    !editing && canLoad
        ? [
              ...// do not render in external dashboard since you cannot segue
              (!isExternal
                  ? [
                        {
                            dataCy: "widget-header-search-icon",
                            icon: "search-plus",
                            iconFamily: "fas",
                            id: "widget-header-search-icon",
                            onClick: () => {
                                showInSearch(
                                    selectShowInSearchPermanentFilters({
                                        ...(dataSources[0].additional_filters && {
                                            filters: [dataSources[0].additional_filters],
                                        }),
                                        safedSearch: dataSources.length && dataSources[0].safed_search,
                                    }),
                                )
                            },
                            size: "lg",
                        },
                    ]
                  : []),
              ...(!isExternal || (isExternal && !externalDownloadsDisabled)
                  ? [
                        {
                            dataCy: "widget-header-download-icon",
                            icon: isDownloading ? "spinner" : "download",
                            iconFamily: "fas",
                            id: "widget-header-download-icon",
                            onClick: async () => {
                                if (!isDownloading) {
                                    setIsDownloading(true)
                                    // grab the evaluated list_widget data from the WidgetEngine
                                    // (also pass optional additionalFilters in case we are searching in the ListWidget Input bar)
                                    await evaluateWidget({
                                        ...widget,
                                        data_sources: dataSources.map((dataSource) => ({
                                            ...dataSource,
                                            ...(additionalFilters ? { additional_filters: additionalFilters } : {}),
                                            limit: -1,
                                        })),
                                        ...(dehydrateExtras
                                            ? // matches dehydrate_extra?:dehydrate_extra schema in app/widgets/types.py ListWidget
                                              { dehydrate_extra: dehydrateExtras }
                                            : {}),
                                        format: "csv",
                                    }).then((response) => {
                                        const anchor = document.createElement("a")
                                        anchor.href = window.URL.createObjectURL(
                                            new Blob([response], { type: "text/csv" }),
                                        )
                                        anchor.download = `List ${widgetKey} from Dashboard ${dashboardId}.csv`
                                        anchor.click()
                                    })
                                    setIsDownloading(false)
                                }
                            },
                            size: "lg",
                            spin: isDownloading,
                        },
                    ]
                  : []),
              // always show the download icon
              ...headerIcons,
          ]
        : headerIcons
