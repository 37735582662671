// !Important!
// This component is imported into the new_grassroots bundle!
// Do NOT import any DS components into this file, or they will
// be included in the GR bundle, doubling its size!

import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { InputGroup } from "react-bootstrap"
import RTATextarea from "react-textarea-autosize"

import { useFocusInput } from "shared/hooks/useFocusInput"

import { addressInitializingFn } from "shared/imports/validationFunctions"
import ReduxFormFieldWrapper from "forms/components/ReduxFormFieldWrapper"

import "app/static/stylus/FormStyles.styl"
import "forms/stylus/InputField.styl"

export const InputField = (props) => {
    const {
        input: { value, onChange, onBlur },
        meta: { error, warning, touched },
        type,
        // support passing additional classnames from Field
        className,
        // assist the browser in suggesting autocomplete values. https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
        autoComplete,
        autoFocus,
        dataCy,
        isTextarea,
        maxRows, // A prop for react-textarea-autosize textareas
        minRows, // A prop for react-textarea-autosize textareas
        label,
        disabled,
        placeholder,
        tooltipText,
        accessibilityId,
        // Boolean to determine if the error should be shown before the field is 'touched'. Defaulted to true
        displayErrorWithoutTouch,
        displayGoogleAutoComplete,
        labelStyle,
        style,
        buttonGroup,
        maxLength,
        // Icon decorator
        IconComponent,
        icon,
        iconFamily,
        renderErrorBelowField,
        showAsterisk,
        shouldUseManualErrorDisplay,
        isCampaignPage,
    } = props

    const focusRef = useFocusInput()

    const GOOGLE_AUTO_COMPLETE = "GOOGLE_AUTO_COMPLETE"

    const googleAutoCompleteUpdate = (autoCompleteValue) => onChange(autoCompleteValue[GOOGLE_AUTO_COMPLETE])

    const initializeAutoComplete = (node) => addressInitializingFn(node, googleAutoCompleteUpdate, GOOGLE_AUTO_COMPLETE)

    const serializedInitialValue = (text) => {
        try {
            return decodeURIComponent(text)
        } catch (e) {
            return text
        }
    }

    const inputProps = {
        className: classNames(
            isCampaignPage ? "ngg-input-field" : "input-field",
            "form-control", // Needs to be specified for Textarea
            {
                "input-error-outline": error && (displayErrorWithoutTouch || touched) && !isCampaignPage,
                "ngg-input-error-outline": error && (displayErrorWithoutTouch || touched) && isCampaignPage,
                "input-warning-outline": warning && (displayErrorWithoutTouch || touched),
                "input-disabled": disabled,
                "textarea-field": isTextarea,
            },
        ),
        "data-cy": dataCy,
        autoFocus,
        autoComplete,
        disabled,
        onBlur,
        onChange,
        placeholder,
        style,
        type,
        value: serializedInitialValue(value),
        maxLength,
        meta: {
            error,
            warning,
            touched,
        },
        id: accessibilityId,
    }

    const ref = displayGoogleAutoComplete ? initializeAutoComplete : autoFocus ? focusRef : undefined

    const inputField = isTextarea ? (
        <RTATextarea maxRows={maxRows} minRows={minRows} {...inputProps} />
    ) : (
        <div className="input-field-wrapper">
            <input {...inputProps} ref={ref} />
            {shouldUseManualErrorDisplay &&
                touched &&
                ((error && <span class="form-input-error">{error}</span>) || (warning && <span>{warning}</span>))}
            {IconComponent && <IconComponent className="icon" icon={icon} iconFamily={iconFamily} />}
        </div>
    )

    return (
        <ReduxFormFieldWrapper
            className={classNames("input", className)}
            displayErrorWithoutTouch={displayErrorWithoutTouch}
            error={error}
            label={label}
            labelStyle={labelStyle}
            style={style}
            tooltipText={tooltipText}
            touched={touched}
            warning={warning}
            accessibilityId={accessibilityId}
            renderErrorBelowField={renderErrorBelowField}
            showAsterisk={showAsterisk}
        >
            {buttonGroup ? (
                <InputGroup>
                    {inputField}
                    {buttonGroup()}
                </InputGroup>
            ) : (
                inputField
            )}
        </ReduxFormFieldWrapper>
    )
}

InputField.defaultProps = {
    accessibilityId: "",
    buttonGroup: undefined,
    disabled: false,
    displayErrorWithoutTouch: true,
    displayGoogleAutoComplete: false,
    isTextarea: false,
    type: "text",
    maxRows: undefined,
    minRows: 3,
}

InputField.propTypes = {
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool,
    accessibilityId: PropTypes.string,
    buttonGroup: PropTypes.func,
    dataCy: PropTypes.string,
    disabled: PropTypes.bool,
    displayErrorWithoutTouch: PropTypes.bool,
    displayGoogleAutoComplete: PropTypes.bool,
    input: PropTypes.object.isRequired,
    isTextarea: PropTypes.bool,
    label: PropTypes.string,
    labelStyle: PropTypes.object,
    meta: PropTypes.object.isRequired,
    maxRows: PropTypes.number,
    minRows: PropTypes.number,
    placeholder: PropTypes.string,
    tooltipText: PropTypes.string,
    type: PropTypes.string,
}

export default InputField
