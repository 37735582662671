import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import WriteMessageFormSection from "QuorumGrassroots/campaign-forms/components/WriteMessageFormSection"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"

const UnconnectedMessageGroupLayout = ({ t, ...props }) => {
    const draftOption = useMemo(
        () =>
            props.messageGroups.find((group) => Number(group.id) === Number(props.activeGroupId))
                ?.advocate_editing_permission,
        [props.activeGroupId, props.messageGroups],
    )

    const currentTargetedMessage = useMemo(
        () => props.targetedMessages?.[props.activeGroupId],
        [props.targetedMessages, props.activeGroupId],
    )

    return (
        <div className="message-group-layout">
            <WriteMessageFormSection
                activeId={props.activeGroupId}
                draftOption={draftOption}
                formName={props.formName}
                key={props.activeGroupId}
                maxLength={props.maxLength}
                isCampaignPage
                // Redux form prop
                submitting={props.submitting}
                t={t}
                uniqueWidgetId={props.uniqueWidgetId}
                writeSectionConfigs={props.writeSectionConfigs}
                // props for EditableMessagePreview
                inputBodyHasEditAndVisualizationMode={isFeatureEnabled("ff_ngg_message_preview")}
                currentTargetedMessage={currentTargetedMessage}
                activeGroupId={props.activeGroupId}
                selectedTargetId={props.firstOfficialSelectedId}
                isLoadingBody={props.isLoadingBody}
                setWrongPlaceholder={props.setWrongPlaceholder}
                wrongPlaceholder={props.wrongPlaceholder}
            />
        </div>
    )
}

UnconnectedMessageGroupLayout.propTypes = {
    activeGroupId: PropTypes.string.isRequired,
    formName: PropTypes.string.isRequired,
    maxLength: PropTypes.number,
    uniqueWidgetId: PropTypes.string.isRequired,
    writeSectionConfigs: PropTypes.object,
    targetedMessages: PropTypes.object,
    firstOfficialSelectedId: PropTypes.number,
    setWrongPlaceholder: PropTypes.func,
    wrongPlaceholder: PropTypes.string,
}

const mapStateToProps = (state) => {
    return {
        targetedMessages: state.widgets.toJS().targetedMessages,
    }
}

export const MessageGroupLayout = connect(mapStateToProps)(UnconnectedMessageGroupLayout)
