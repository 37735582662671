import { CloseButton as MantineCloseButton } from "@mantine/core"
import styled from "styled-components"
import { constants } from "QuorumGrassroots/constants"
import { MOBILE_WIDTH } from "QuorumGrassroots/framework/hooks/useDimensions"

export const VideoContainer = styled.div`
    width: 100%;
    height: 100vh;

    max-width: 960px;
    max-height: 540px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
`

export const Video = styled.video`
    max-width: 960px;
    max-height: 540px;

    /* uses vw on both to keep the aspect ratio of 16/9 */
    width: 80vw !important;
    height: 45vw;

    border: none;
    outline: none;

    padding: 0;
    margin: 0;

    @media (max-width: 960px) {
        width: 100% !important;
        height: 100%;
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
        max-height: 100%;
        height: 100vh;
    }
`

export const CloseButton = styled(MantineCloseButton)`
    color: white;

    position: absolute;
    right: 0.5rem;
    top: 0.5rem;

    width: 3rem;
    height: 3rem;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 2;

    border-radius: 32px;
    transition: color 0.25s ease-in-out;
    transition: background-color 0.25s ease-in-out;
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.7));

    &:hover {
        color: ${constants.freshMediumGrey};
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
        top: 2rem;
    }
`
