import React from "react"
import classes from "@/pages/search/grassroots/VideoModal/VideoModal.module.css"
import { Button, CloseButton, Modal } from "@mantine/core"
import { Icon } from "@/components/Icon"

interface Props {
    videoUrl: string
    videoThumbnail: string
    isOpen: boolean
    onClose: () => void
    openApproveModal: () => void
    openRejectModal: () => void
    hideApprove: boolean
}

export const VideoModal = (props: Props) => {
    return (
        <Modal
            opened={props.isOpen}
            onClose={props.onClose}
            size="auto"
            withCloseButton={false}
            closeOnClickOutside={true}
            centered
            classNames={{ body: classes.videoModal }}
        >
            <CloseButton
                aria-label="Close modal"
                className={classes.closeButton}
                onClick={props.onClose}
                size="xl"
                iconSize={20}
            />
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <video controls className={classes.videoIframe} poster={props.videoThumbnail} data-cy="story-video">
                <source src={props.videoUrl} type="video/mp4" />
            </video>
            <div className={classes.buttonsWrapper}>
                {!props.hideApprove && (
                    <Button
                        className={classes.approve}
                        leftIcon={<Icon icon="check" />}
                        onClick={props.openApproveModal}
                    >
                        Approve
                    </Button>
                )}
                <Button
                    className={classes.reject}
                    leftIcon={<Icon icon="times" />}
                    onClick={props.openRejectModal}
                    variant="outline"
                >
                    Reject
                </Button>
            </div>
        </Modal>
    )
}
