import React from "react"
import { Field } from "redux-form/immutable"

import { AddressFormComponent } from "shared-web/forms/components/AddressFormComponent"
import { ADDRESS_KEY } from "shared-web/forms/constants"

export const AddressField = (props) => {
    const formatFunc = (addressData) => {
        // if the address form input is an object, that means we're getting
        // data back from Google Maps and we'll want to use it.
        //
        // If it's just a string, this means that the user has inputted
        // an address or edited and address without touching the Google Maps
        // autocomplete. If that's the case, there's nothing to format!
        return addressData && props.shouldGeocode && typeof addressData === "object"
            ? addressData[ADDRESS_KEY]
            : addressData
    }

    return <Field {...props} component={AddressFormComponent} format={formatFunc} />
}
