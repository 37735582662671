import { Group, CloseButton as MantineCloseButton, Modal } from "@mantine/core"
import styled from "styled-components"
import { constants } from "QuorumGrassroots/constants"
import { MOBILE_WIDTH } from "QuorumGrassroots/framework/hooks/useDimensions"

export const RecorderModal = styled(Modal)<{ recorderReady: boolean }>`
    & .mantine-Modal-content {
        background-color: transparent;
        ${(props) => !props.recorderReady && `box-shadow: none;`}
    }
`

export const RecorderContainer = styled.div`
    width: 100%;
    height: 100vh;

    max-width: 960px;
    max-height: 540px;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    & #myFirstCamera {
        max-width: 960px;
        max-height: 540px;
        /* uses vw on both to keep the aspect ratio of 16/9 */
        width: 80vw !important;
        height: 45vw;
        border: none;
    }

    @media (max-width: 960px) {
        & #myFirstCamera {
            width: 100% !important;
            height: 100%;
        }
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
        max-height: 100%;

        & #myFirstCamera {
            max-height: 100%;
            height: 100dvh;
        }
    }
`

export const CloseButton = styled(MantineCloseButton)`
    color: white;

    position: absolute;
    right: 0.5rem;
    top: 0.5rem;

    width: 2rem;
    height: 2rem;

    display: flex;
    ${(props) => props.hidden && `display: none;`}
    justify-content: center;
    align-items: center;

    z-index: 2;

    border-radius: 32px;
    transition: color 0.25s ease-in-out;
    transition: background-color 0.25s ease-in-out;
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.7));

    &:hover {
        color: ${constants.freshMediumGrey};
    }

    @media (max-width: ${MOBILE_WIDTH}px) {
        top: 2rem;
    }
`

export const ActionContainer = styled(Group)<{ hidden: boolean }>`
    ${(props) => props.hidden && `visibility: hidden;`}
`

export const GroupButtons = styled.div`
    display: flex;
    position: fixed;
    top: 85%;
    left: 50%;
    transform: translateX(-50%);

    gap: 8px;
    padding: 8px !important;
    border-radius: 64px;
    background: rgba(0, 0, 0, 0.4);
`

export const ActionButtons = styled.button`
    display: flex;
    align-items: center;
    height: 48px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 32px;
    border: none;
`

export const RedoButton = styled(ActionButtons)`
    background: transparent;
    color: ${constants.whiteColor};
`

export const SaveButton = styled(ActionButtons)`
    color: ${constants.colors.gray[9]};
    background: ${constants.whiteColor};
`

export const LabelButton = styled.span`
    text-overflow: ellipsis;
    font-family: Roboto;
    font-feature-settings:
        "clig" off,
        "liga" off;
    font-size: 16px;
    font-style: normal;
    line-height: 100%;
    font-weight: 400;
`
