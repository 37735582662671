import { useEffect, useState } from "react"

export const MOBILE_WIDTH = 480
export const TABLET_WIDTH = 768

export const useDimensions = () => {
    const [dimensions, setDimensions] = useState({
        height: undefined,
        width: undefined,
    })

    const onResize = () => {
        setDimensions({
            height: window.innerHeight,
            width: window.innerWidth,
        })
    }

    useEffect(() => {
        onResize()
        window.addEventListener("resize", onResize)
        return () => window.removeEventListener("resize", onResize)
    }, [])

    return {
        ...dimensions,
        isMobile: dimensions.width <= MOBILE_WIDTH,
        isTablet: dimensions.width <= TABLET_WIDTH,
    }
}
