import React from "react"
import { isFeatureEnabled } from "shared/featureflags/helperFunctions"
import { centsToCurrencyStr } from "shared/pac/helperFunctions"

const { TransactionMethodType } = DjangIO.app.ledger.types

const DonationTierLabel = ({ donationMethod, conditionalGivingLevelLabel, value, tierType, index, frequencyLabel }) => {
    const donationValue = tierType === "amount" ? centsToCurrencyStr(value) : value

    if (!value) {
        return <span id={`donation-tier-label-${index}`}>Other</span>
    }

    if (!conditionalGivingLevelLabel) {
        return <span id={`donation-tier-value-${index}`}>{donationValue}</span>
    }

    return (
        <>
            <span id={`donation-tier-label-${index}`}>{conditionalGivingLevelLabel}</span>
            <span id={`donation-tier-hyphen-${index}`}> - </span>
            <span id={`donation-tier-value-${index}`}>
                {donationValue}
                {isFeatureEnabled("ff_pac_action_center_new_design") ? (
                    <span id={`donation-tier-frequency-${index}`}>
                        {donationMethod !== TransactionMethodType.check.value ? ` / ${frequencyLabel}` : " "}
                    </span>
                ) : (
                    ""
                )}
            </span>
        </>
    )
}

export default DonationTierLabel
