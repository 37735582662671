// vendor modules
import React, { Component } from "react"
import PropTypes from "prop-types"
// internal modules
import Select from "app/static/frontend/selects/components/Select"

// NOTE: this could potentially be a functional component
// NOTE: loadOptions, regardless of synchronicity requirements, should return
// an array with items of the form { value: 'value', label: 'label' }
export default class DiscreteSelect extends Component {
    static initClass() {
        this.defaultProps = {
            multi: false,
            label: "label",
        }
        this.requiredProps = {
            getItems: PropTypes.func.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        }
    }

    render() {
        return (
            <Select
                {...this.props}
                async={false}
                dataCy={this.props.dataCy}
                multi={this.props.multi}
                loadOptions={this.props.getItems}
                onChange={this.props.onChange}
                onMenuOpen={this.props.onMenuOpen}
                value={this.props.value}
                filterOption={this.props.filterOption}
                shouldHideExtraValues={true}
            />
        )
    }
}
DiscreteSelect.propTypes = {
    onMenuOpen: PropTypes.func,
}
