import React from "react"
import { MentionsInput } from "react-mentions"
import { editablePlaceholderMap } from "QuorumGrassroots/helperFunctions"

import {
    StyledTextareaContainer,
    StyledMention,
} from "QuorumGrassroots/framework/components/HighlightedTextarea/HighlightedTextarea.styles"
import { useDebouncedCallback } from "QuorumGrassroots/framework/hooks/useDebouncedCallback"

const validPlaceholders = Object.values(editablePlaceholderMap)

export const HighlightedTextarea = ({ className, onChange, setWrongPlaceholder, ...props }) => {
    const handleInvalidPlaceholders = useDebouncedCallback((value: string) => {
        const placeholdersInText = value.match(/\{\{[^}]+\}\}/g)
        if (!placeholdersInText) {
            setWrongPlaceholder("")
            return
        }
        const invalidPlaceholder = placeholdersInText.find((placeholder) => !validPlaceholders.includes(placeholder))
        invalidPlaceholder ? setWrongPlaceholder(invalidPlaceholder) : setWrongPlaceholder("")
    }, 300)

    return (
        <StyledTextareaContainer>
            <MentionsInput
                {...props}
                onChange={(e) => {
                    const value = e.target.value

                    onChange(value)
                    handleInvalidPlaceholders(value)
                }}
                className={`highlighted-textarea ${className}`}
            >
                <StyledMention markup="{{__id__}}" displayTransform={(id, display) => `{{${display}}}`} />
            </MentionsInput>
        </StyledTextareaContainer>
    )
}
