import { Modal } from "@mantine/core"
import React from "react"

import * as S from "QuorumGrassroots/campaign-forms/components/StoryForm/VideoModal/VideoModal.styles"
import { useDimensions } from "QuorumGrassroots/framework/hooks/useDimensions"

interface VideoModalProps {
    videoURL: string

    isOpened: boolean
    onClose: () => void
}

export const VideoModal = (props: VideoModalProps) => {
    const { isMobile } = useDimensions()

    return (
        <Modal
            opened={props.isOpened}
            // need to set this because mobile menu has z-index of 1000
            zIndex={1001}
            padding={0}
            onClose={props.onClose}
            withCloseButton={false}
            closeOnClickOutside={false}
            fullScreen={isMobile}
            size="960px"
            centered
        >
            <S.VideoContainer>
                <S.CloseButton onClick={props.onClose} aria-label="Close video" />
                <S.Video controls>
                    <source src={props.videoURL} type="video/mp4" />
                </S.Video>
            </S.VideoContainer>
        </Modal>
    )
}
