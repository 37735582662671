import i18n from "i18n"
import { Map, List, OrderedSet, fromJS } from "immutable"
import { createSelector, createStructuredSelector } from "reselect"

import {
    createPageTitleSelector,
    selectCampaignList,
    selectCampaignListLoading,
} from "QuorumGrassroots/framework/selectors"

import { selectAugmentedWidgetContent } from "QuorumGrassroots/widgets/selectors"

// campaigns associated with issues can either be URIs or dehydrated.
// This is intentional, albeit confusing. This is so we don't have to load
// campaigns again if we don't have to.
export const selectIssueCampaignArray = createSelector(selectAugmentedWidgetContent, (issue) => issue.campaigns || [])

export const selectHasIssueCampaigns = createSelector(selectIssueCampaignArray, (campaigns) =>
    Boolean(campaigns.length),
)

export const selectIssueUsesCampaignUris = createSelector(selectIssueCampaignArray, (campaigns) =>
    campaigns.every((campaign) => typeof campaign === "string"),
)

export const selectIssueCampaigns = createSelector(
    [selectIssueCampaignArray, selectIssueUsesCampaignUris, selectCampaignList, selectCampaignListLoading],
    (campaigns, usesUris, campaignList, campaignListLoading) => {
        // if it doesn't use URIs, it's received the full campaign
        // object. If that's the case, just pass it on!
        if (!usesUris) {
            return campaigns
                .filter(
                    (campaign) => campaign.status !== DjangIO.app.grassroots.campaign.types.CampaignStatus.draft.value,
                )
                .filter((campaign) => campaign.visible_on_action_center)
                .sort((campaignA, campaignB) => campaignA.order - campaignB.order)
        }

        // otherwise, campaigns are in URI form and we're pulling campaigns
        // from the CampaignList section of the store.
        if (campaignListLoading) {
            return campaigns.map((uri) => ({ key: uri }))
        }

        // great, now we have the full campaign list. Let's get the data
        // from here!
        const campaignIds = campaigns.map((uri) =>
            DjangIO.app.grassroots.campaign.models.GrassrootsCampaign.idFromResourceUri(uri),
        )

        return campaignList
            .filter(
                (campaign) =>
                    campaign.get("status") !== DjangIO.app.grassroots.campaign.types.CampaignStatus.draft.value,
            )
            .filter((campaign) => campaignIds.includes(campaign.get("id")))
            .filter((campaign) => campaign.get("visible_on_action_center"))
            .sortBy((campaign) => campaign.get("order"))
            .toJS()
    },
)

export const selectWidgetWidthSize = createSelector(selectAugmentedWidgetContent, (content) =>
    content.grassroots_page_layout_type
        ? DjangIO.app.grassroots.campaign.types.CampaignPageLayout.by_value(content.grassroots_page_layout_type)
              .column_width
        : 12,
)

export const selectIssueName = createSelector(selectAugmentedWidgetContent, (content) => content.name)

export const issueSelectors = createStructuredSelector({
    hasIssueCampaigns: selectHasIssueCampaigns,
    issueCampaigns: selectIssueCampaigns,
    pageTitle: createPageTitleSelector(selectIssueName),
    widgetWidthSize: selectWidgetWidthSize,
})
