import {
    replaceTargetedMessageSupporterNamePlaceholders,
    storeSupporterPlaceholderValues,
} from "QuorumGrassroots/campaign-forms/action-creators"
import React, { Dispatch } from "react"
import { connect } from "react-redux"
import { Field, formValueSelector } from "redux-form/immutable"
import { SupporterRegisterFields } from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/interfaces"
import { parseAddress } from "QuorumGrassroots/widgets/ReusableComponents/UserInfoFormSection/helper"

export const withPlaceholderResolving = (Component: React.ComponentType<any>) => {
    const Wrapper = (props: any) => {
        if (!["firstname", "lastname"].includes(props.name)) {
            return <Component {...props} />
        }
        const { addressText } = parseAddress(props.address)

        const replaceSupporterPlaceholder = () => {
            props.replaceTargetedMessageSupporterNamePlaceholders(props.campaign.id.toString(), {
                firstname: props.firstname,
                lastname: props.lastname,
                address: addressText,
                email: props.email,
            })
            props.storeSupporterPlaceholderValues(props.campaign.id.toString(), {
                firstname: props.firstname,
                lastname: props.lastname,
                address: addressText,
                email: props.email,
            })
        }
        return <Component {...props} onBlur={replaceSupporterPlaceholder} />
    }

    Wrapper.displayName = `withPlaceholderResolving(${Component.displayName || Component.name || "Component"})`

    const selector = formValueSelector("registration")

    const mapStateToProps = (state) => {
        return {
            address: selector(state, "input_address"),
            firstname: selector(state, "firstname"),
            lastname: selector(state, "lastname"),
            email: selector(state, "email"),
        }
    }

    const mapDispatchToProps = (
        dispatch: Dispatch<{ type: string; uniqueWidgetId: string; supporter: SupporterRegisterFields }>,
    ) => ({
        replaceTargetedMessageSupporterNamePlaceholders: (uniqueWidgetId: string, messages: Record<string, unknown>) =>
            dispatch(replaceTargetedMessageSupporterNamePlaceholders(uniqueWidgetId, messages)),

        storeSupporterPlaceholderValues: (uniqueWidgetId: string, supporter: SupporterRegisterFields) =>
            dispatch(storeSupporterPlaceholderValues(uniqueWidgetId, supporter)),
    })

    return connect(mapStateToProps, mapDispatchToProps)(Wrapper)
}

export const FieldWithPlaceholderResolving = withPlaceholderResolving(Field)
