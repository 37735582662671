import React from "react"

import * as S from "QuorumGrassroots/campaign-forms/components/StoryForm/RecorderModal/RecorderModal.styles"
import { useDimensions } from "QuorumGrassroots/framework/hooks/useDimensions"
import { RecorderStatus } from "QuorumGrassroots/framework/hooks/useRecorder/useRecorder"

interface RecordModalProps {
    isOpened: boolean
    onClose: () => void

    recorder: unknown
    recorderStatus: RecorderStatus
}

export const RecorderModal = (props: RecordModalProps) => {
    const { isMobile } = useDimensions()

    const shouldHideCloseButton = [
        RecorderStatus.RECORDING,
        RecorderStatus.COUNTDOWN,
        RecorderStatus.WAITING_APPROVAL,
    ].includes(props.recorderStatus)

    return (
        <S.RecorderModal
            // need to set this because mobile menu has z-index of 1000
            zIndex={1001}
            padding={0}
            opened={props.isOpened}
            onClose={props.onClose}
            withCloseButton={false}
            closeOnClickOutside={false}
            closeOnEscape={false}
            fullScreen={isMobile}
            size="960px"
            centered
            keepMounted
        >
            <S.RecorderContainer>
                <S.CloseButton onClick={props.onClose} aria-label="Close recording" hidden={shouldHideCloseButton} />
                <div id="camera-tag">
                    <div className="playback-screen">
                        <S.GroupButtons>
                            <S.RedoButton className="cameratag_record">
                                <S.LabelButton>Redo</S.LabelButton>
                            </S.RedoButton>
                            <S.SaveButton className="cameratag_publish">
                                <S.LabelButton>Save</S.LabelButton>
                            </S.SaveButton>
                        </S.GroupButtons>
                    </div>
                </div>
            </S.RecorderContainer>
        </S.RecorderModal>
    )
}
