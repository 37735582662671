import { useCallback, useEffect, useState } from "react"

import { loadCameraTagAssets } from "QuorumGrassroots/framework/hooks/useRecorder/useRecorder.helper"

export enum RecorderStatus {
    LOADING,
    INITIALIZED,
    READY,
    COUNTDOWN,
    CAMERA_DENIED,
    RECORDED,
    RECORDING,
    WAITING_APPROVAL,
    PUBLISHING,
    PUBLISHED,
    PROCESSED,
}

export type VideoPayload = {
    thumbnail: string
    videoUrl: string
    videoId: string
}

type UseRecorderReturn = [RecorderStatus, unknown, VideoPayload | null]

const CAMERATAG_DIV_ID = "camera-tag"
const CAMERATAG_CAM_ID = "myFirstCamera"
const CAMERATAG_APP_UUID = "a-c1694580-7aaa-013d-ad21-0abe8b919efd"

export const useRecorder = (shouldStartRecording = false): UseRecorderReturn => {
    const [recorderStatus, setRecorderStatus] = useState<RecorderStatus>(RecorderStatus.LOADING)
    const [recorder, setRecorder] = useState(null)
    const [video, setVideo] = useState<VideoPayload | null>(null)

    const onLoadCameraTag = useCallback(() => {
        window.CameraTag.init(CAMERATAG_DIV_ID, "camera", {
            appUuid: CAMERATAG_APP_UUID,
            id: CAMERATAG_CAM_ID,
            pollServer: true,
        })

        window.CameraTag.observe(CAMERATAG_CAM_ID, "initialized", function () {
            this.connect()
            setRecorder(this)
            setRecorderStatus(RecorderStatus.INITIALIZED)
        })

        window.CameraTag.observe(CAMERATAG_CAM_ID, "cameraDenied", function () {
            setRecorderStatus(RecorderStatus.CAMERA_DENIED)
        })

        window.CameraTag.observe(CAMERATAG_CAM_ID, "readyToRecord", function () {
            setRecorderStatus(RecorderStatus.READY)
        })

        window.CameraTag.observe(CAMERATAG_CAM_ID, "readyToPublish", function () {
            setRecorderStatus(RecorderStatus.WAITING_APPROVAL)
        })

        window.CameraTag.observe(CAMERATAG_CAM_ID, "countdownStarted", function () {
            setRecorderStatus(RecorderStatus.COUNTDOWN)
        })

        window.CameraTag.observe(CAMERATAG_CAM_ID, "recordingStarted", function () {
            setRecorderStatus(RecorderStatus.RECORDING)
        })

        window.CameraTag.observe(CAMERATAG_CAM_ID, "publishing", function () {
            setRecorderStatus(RecorderStatus.PUBLISHING)
        })

        window.CameraTag.observe(CAMERATAG_CAM_ID, "cameraReset", function () {
            setRecorderStatus(RecorderStatus.READY)
            setVideo(null)
        })

        window.CameraTag.observe(CAMERATAG_CAM_ID, "published", function () {
            setVideo({
                thumbnail: window.CameraTag.cameras.myFirstCamera.getVideo().medias.thumb,
                videoUrl: window.CameraTag.cameras.myFirstCamera.getVideo().medias.mp4,
                videoId: window.CameraTag.cameras.myFirstCamera.getVideo().uuid,
            })
            setRecorderStatus(RecorderStatus.PUBLISHED)
        })

        window.CameraTag.observe(CAMERATAG_CAM_ID, "processed", function () {
            setRecorderStatus(RecorderStatus.PROCESSED)
        })
    }, [])

    useEffect(() => {
        if (!window.CameraTag && shouldStartRecording) {
            const isCameraTagCSSLoaded = !!document.getElementById("camera-tag-css")

            const [link, script] = loadCameraTagAssets()
            script.onload = onLoadCameraTag

            if (!isCameraTagCSSLoaded) document.head.appendChild(link)
            document.body.appendChild(script)

            setRecorderStatus(RecorderStatus.LOADING)

            return () => {
                document.body.removeChild(script)
            }
        }
    }, [onLoadCameraTag, shouldStartRecording])

    return [recorderStatus, recorder, video]
}
